/* Fix An Appointment Component */
.fix-an-appointment-container {
  /* background-color: #eff6ff; */
}
.fix-an-appointment-wrapper {
  margin: auto;
  padding: 4rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1035px;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 20px;
}
.fix-an-appointment-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 130%;
  text-align: center;
  max-width: 800px;
  color: #022282;
  margin-bottom: 10px;
  span {
    color: #ff511a;
  }
}

.fix-an-appointment-infos {
  max-width: 428px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.fix-an-appointment-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #161616;
  margin-bottom: 40px;
}
.web-fix-an-appointment-button {
  width: fit-content !important;
  background-color: #022282 !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  padding: 10px 87px !important;
  text-transform: none !important;
  position: unset !important;
  height: 48px;
}
.mobile-fix-an-appointment-button {
  display: none !important;
}
@media (max-width: 1035px) {
  .statistics {
    flex-direction: column !important;
    align-items: center;
  }
}
@media (max-width: 997px) {
  .fix-an-appointment-wrapper {
    padding: 60px 20px !important;
  }

  .fix-an-appointment-description {
    text-align: center;
    margin-bottom: 0px;
  }
  .web-fix-an-appointment-button {
    display: none !important;
  }
  .fix-an-appointment-icon-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .mobile-fix-an-appointment-button {
    display: block !important;
    width: fit-content !important;
    background-color: #022282 !important;
    border-radius: 8px !important;
    color: #ffffff !important;
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    padding: 13px 87px !important;
    text-transform: none !important;
    margin-top: 20px !important;
  }
}

@media (max-width: 520px) {
  .fix-an-appointment-title {
    font-size: 20px !important;
  }
  .fix-an-appointment-description {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
  }
  .mobile-fix-an-appointment-button {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 13px 70px !important;
  }
  .fix-an-appointment-icon {
    width: 265px;
    height: 196px;
  }
}
@media (max-width: 410px) {
  .fix-an-appointment-icon {
    width: 300px;
    height: 222px;
  }
  .mobile-fix-an-appointment-button {
    padding: 10px 17px !important;
  }
}
/* Preventive Actions Cards Component */
.preventive-cards-wrapper {
  background-color: #ffffff;
}
.preventive-cards-container,
.formation-cards-container {
  margin: auto;
  padding: 80px 20px;
  max-width: 1035px;
}

.preventive-cards-container {
  padding-bottom: 10px;
}
.preventive-cards-title,
.formation-cards-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;

  text-align: center;
  color: #022282;
  margin-bottom: 50px;
}
.preventive-cards-title span {
  color: #ff511a;
  display: block;
}
.preventive-cards-description {
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin: 0px 15px 50px;
}
.cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  gap: 25px;
}
.card,
.formation-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #c7c7c7;
  padding: 22px 15px 20px;
  width: 230px;
  background: #fff;
}
.formation-card {
  background-color: #ffffff;
}
.card-title {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  margin-top: 20px;
}
.formation-cards-container .card-title {
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  color: #161616;
  margin-top: 20px;
}
.formation-cards-container .card-description {
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  color: #161616;
  text-align: center;
}
.card-description {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  text-align: center;
}
@media (max-width: 520px) {
  .preventive-cards-title {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 20px 20px;
  }
  .preventive-cards-title span {
    display: inline;
  }
  .preventive-cards-description {
    font-size: 16px;
    line-height: 24px;
    margin: 0px 20px 30px;
  }
  .cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .card {
    padding: 30px 7px 10px;
    width: 160px;
    align-self: normal;
  }
  .card-title,
  .card-description {
    font-size: 12px;
    line-height: 18px;
  }
  .card-icon {
    width: 60px;
    height: 60px;
  }
}

/* Statistics Cards Component */
.statistics-cards-container {
  background-color: #022282;
  /* background-image: url("../assets/images/background-icon.svg"); */
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -45px;
}
.statistics-cards-wrapper {
  margin: auto;
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 995px;
  gap: 2rem;
  .statistics {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    .card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h2 {
        color: #ffffff;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        a {
          color: #ffffff;
        }
      }
      .statistics-cards {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        .statistics-card {
          display: flex;
          flex-direction: column;
          align-self: center;
          justify-content: center;
          gap: 1rem;
          border-radius: 34px;
          background-color: #ffffff;
          padding: 1rem;
          width: 200px;
          height: 200px;
          text-align: center;
          justify-content: center;
          position: relative;
          .info-card {
            position: absolute;
            left: 10.2rem;
            top: 10.5rem;
            cursor: pointer;
          }
          p {
            font-weight: 600;
            color: #161616;
            font-size: 1.2rem;
          }
          h3 {
            font-family: Poppins;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 40px;
            color: #ff511a;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto 4px;
          }
          span {
            font-weight: 600;
            font-size: 25px;
            line-height: 34.25px;
          }
        }
      }
    }
  }
}

.statistics-title {
  font-family: Poppins;
  color: #ffffff;
  margin: 0px auto 25px;
  text-align: center;
  h2 {
    line-height: 31.5px;
    font-weight: 700;
    font-size: 32px;
    margin: 0 5rem 2rem;
  }
  p {
    font-weight: 400;
    font-size: 18px;
  }
}
.statistics-title span {
  color: #1cecba;
}
.statistics-description {
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 30.14px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 68px;
}
/* .statistics {
  display: flex;
  justify-content: center;
  column-gap: 62px;
  flex-wrap: wrap;
  row-gap: 40px;
} */
/* .cards {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 21px;
}
 */

.left-card-reason {
  font-family: Poppins;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #161616;
  text-align: center;
}
.left-card-reason span {
  color: #ff511a;
}
.right-card-reason {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 27.4px;
  color: #161616;
  text-align: center;
  width: 133%;
}
.right-card-reason span {
  color: #ff511a;
}

.cards-description {
  font-family: Poppins;
  font-weight: 700;
  font-size: 22px;
  line-height: 30.14px;
  color: #ffffff;
  text-align: center;
  margin: 20px 0px;
}
.statistics-sources-container {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 36px;
  padding-right: 40px;
}
.statistics-sources {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 40px;
}
@media (max-width: 995px) {
  .statistics-sources-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 520px) {
  .statistics-cards-container {
    background-image: none;
  }
  .statistics-cards-wrapper {
    padding: 2rem 1rem 1rem;
  }
  .card-wrapper {
    align-items: center;
  }
  .statistics-cards {
    flex-wrap: wrap;
    justify-content: center;
    .statistics-card {
      justify-content: center;
      padding: 1rem;
      /* p {
        font-size: 1.3rem !important;
      }
      h3 {
        font-size: 1.5rem !important;
        padding: 0.5rem !important;
      } */
    }
  }
  .statistics-title {
    font-size: 20px;
    h2 {
      margin: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .statistics-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
  }
  .cards {
    column-gap: 15px;
  }
  .card-number {
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 3px;
  }
  .card-number span {
    font-size: 18px;
    line-height: 24.66px;
  }
  .left-card-reason {
    font-size: 16px;
    line-height: 22px;
  }
  .right-card-reason {
    font-size: 15px;
    line-height: 20px;
    width: 138%;
  }
  .cards-description {
    font-size: 14px;
    line-height: 19px;
    margin-top: 14px;
  }
  .statistics-sources-container {
    justify-content: start;
  }
  .statistics-sources {
    font-size: 8px;
    margin-top: 20px;
  }
}
@media (max-width: 375px) {
  .statistics-card {
    padding: 19px 28px;
    width: 150px;
    height: 150px;
  }
}
/* Download Guide Component */
.download-guide-container {
  background-color: #022282;
  background-image: url("../assets/images/background-downloadGuide-icon.svg");
  background-repeat: no-repeat;
  position: relative;
}
.download-guide-content {
  margin: auto;
  padding: 60px 0px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.download-guide-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

.download-guide-content p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
}
.download-guide-content button {
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #022282 !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
  text-transform: none !important;
  padding: 11px 24px !important;
  position: unset !important;
  height: 48px;
}
.download-guide-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}
@media (max-width: 1200px) {
  .download-guide-content {
    padding: 60px 0px 100px;
  }
  .download-guide-icon {
    bottom: 0px;
    right: 8px;
  }
}
@media (max-width: 800px) {
  .download-guide-content {
    padding: 60px 0px 80px;
  }
  .download-guide-icon {
    display: none;
  }
}
@media (max-width: 520px) {
  .download-guide-content {
    padding: 30px 0px 40px;
  }
  .download-guide-content h1 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
    max-width: 335px;
  }
  .download-guide-content p {
    font-size: 12px;
    line-height: 12px;
    max-width: 300px;
  }
  .download-guide-content button {
    font-size: 14px !important;
    padding: 11px 19px !important;
  }
}

/* Sponsors Component */
.sponsors-conatiner {
  background-color: #fff;
}
.sponsors-content {
  margin: auto;
  padding: 20px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.sponsors-content h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  text-align: center;
  margin-bottom: 45px;
}
.sponsors-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 1000px) {
  .one {
    order: 1;
  }
}

@media (max-width: 520px) {
  .sponsors-content h1 {
    font-size: 12px;
    line-height: 16.5px;
    margin-bottom: 25px;
  }
  .sponsors-icons-container {
    gap: 34px;
  }
  .sponsor-icon {
    width: 80px;
    /* height: 52px; */
  }
  .one {
    order: 0;
  }
}
@media (max-width: 427px) {
  .one {
    order: 1;
  }
}

/* Product Header Component */
.product-header-container {
  /* background: #e7f0ff; */
}
.product-header-content {
  margin: auto;
  padding: 5rem 1.5rem 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1035px;
  column-gap: 2rem;
  row-gap: 30px;
}
.product-header-description {
  max-width: 488px;
  span {
    color: #ff511a;
    font-weight: 700;
  }
  .formation-qualiopi {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    gap: 0.5rem;
    p {
      font-size: 1rem;
    }
  }
}
.product-header-description h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 44px;
  line-height: 51px;
  color: #022282;
  margin-bottom: 30px;
}
.product-header-description p {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #161616;
}
.automated-coaching-image {
  width: 467px;
}
.prediction-health-factors-image {
  width: 460px;
}
@media (max-width: 1035px) {
  .product-header-content {
    padding: 0;
  }
  .product-header-description {
    .formation-qualiopi {
      padding: 0;
    }
  }
}
@media (max-width: 768px) {
  .product-header-content {
    padding: 35px 0px;
  }
}
@media (max-width: 520px) {
  .product-header-content {
    padding: 35px 0px;
  }
  .product-header-description {
    padding: 0px 20px;
  }
  .product-header-description h1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  .product-header-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .product-header-content > img {
    width: 335px;
    height: 247px;
  }
  .risk-analysis-image {
    width: 259px;
    height: 200px;
  }
  .prediction-health-factors-image {
    width: 335px;
    height: 274px;
  }
  .automated-coaching-image {
    width: 335px;
    height: 215px;
  }
}
/* Predicta Features Component */
.predicta-features-container {
  /* background-color: #eff6ff; */
}
.predicta-features-content {
  margin: auto;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.predicta-features-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 32px;
  line-height: 41.6px;
  color: #022282;
  margin-bottom: 60px;
  text-align: center;
}
.buttons-container {
  display: flex;
  justify-content: center;
  column-gap: 114px;
  row-gap: 35px;
  flex-wrap: wrap;
}
.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #022282;
  border-radius: 8px;
  padding: 8px 10px;
  width: 410px;
  cursor: pointer;
}
.button-infos {
  display: flex;
  align-items: center;
  column-gap: 13px;
}
.icon-wrapper {
  border: 2px solid #022282;
  padding: 3px 5px;
  border-radius: 5px;
}
.predicta-features-icon {
  width: 29px;
  height: 29px;
}
.button h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 20.7px;
  color: #022282;
}
.predicta-features-content p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #161616;
  margin-top: 100px;
  margin-bottom: 18px;
  text-align: center;
}
.predicta-features-content Button {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  color: #ffffff !important;
  background-color: #022282 !important;
  border-radius: 12px !important;
  box-shadow: unset !important;

  text-transform: none !important;
  padding: 11px 23px !important;
}
.predicta-features-content Button:hover,
.download-guide-content button:hover,
.web-fix-an-appointment-button:hover,
.button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .predicta-features-content {
    padding: 40px 0px;
  }
  .predicta-features-content p {
    padding: 0px 20px;
    margin: 40px 0px 14px;
  }
}
@media (max-width: 520px) {
  .predicta-features-content h1 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .button {
    width: 345px;
    padding: 8px 3px;
  }
  .button-infos {
    column-gap: 10px;
  }
  .predicta-features-icon {
    width: 20px;
    height: 20px;
  }
  .button h6 {
    font-size: 16px;
    line-height: 18px;
  }
  .predicta-features-content p {
    font-size: 14px;
    line-height: 21px;
  }
  .predicta-features-content Button {
    font-size: 14px !important;
    line-height: 26px !important;
    padding: 11px 23px !important;
  }
}
