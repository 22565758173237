* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
a {
  text-decoration: none;
}
@import url("./styles/reusablesComponents.css");
@import url("./styles/aboutUs.css");
@import url("./styles/calendly.css");
@import url("./styles/contact.css");
@import url("./styles/downloadGuide.css");
@import url("./styles/footer.css");
@import url("./styles/formations.css");
@import url("./styles/home.css");
@import url("./styles/navBar.css");
@import url("./styles/product.css");
@import url("./styles/loader.css");

body {
  background: radial-gradient(
      287.74% 100% at 50% 0%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(171.76deg, #032999 0%, rgba(2, 34, 130, 0) 33.36%),
    rgba(231, 240, 255, 0.79);
}
.Mui-error {
  color: #7c7c7c !important;
}
