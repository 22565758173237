.home-header-container {
  /* background-color: #e7f0ff; */
  padding: 4.5rem 0;
}
.home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  justify-content: center;
  /* flex-wrap: wrap; */
  max-width: 1035px;
  /* column-gap: 25px; */
  gap: 3rem;
}
.home-header-description {
  max-width: 498px;
}
.home-header-description h1 {
  font-family: Poppins;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #022282;
  margin-bottom: 1rem;
}
.home-header-description h2 {
  color: #022282;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  span {
    color: #FF511A;
  }
}
.home-header-description p {

  color: #161616;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.home-header-description h1 span,
.home-header-description p span {
  color: #ff511a;
}
.test-predicta-bttn {
  background-color: #ff511a !important;
  border-radius: 8px !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #ffffff !important;
  text-transform: none !important;
  box-shadow: none !important;
  margin-top: 18px !important;
  height: 48px;
    padding: 11px 20px !important;
}
.test-predicta-bttn:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}
.home-header-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-header-image Button {
  border-radius: 8px !important;
  background-color: transparent !important;
  border: 2px solid #ff511a !important;
  color: #022282 !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  padding: 0px 14px;
  box-shadow: none !important;
}
.home-header-image Button:hover {
  color: #ffffff !important;
  background-color: #ff511a !important;
}

@media (max-width: 1035px) {
  .home-header {
    flex-direction: column;
  }
  .home-header-image svg {
    width: 50dvw;
    height: 400px;
  }
  .predicta-steps-wrapper {
    margin: 2rem 0 !important;
    .predicta-step {
      flex-direction: column !important;
      align-items: center;
      padding: 1rem !important;
      .text {
        font-size: 14px;
        width: fit-content;
        ul {
          margin: 0.5rem 0.5rem 0 !important;
        }
      }
      .image {
        display: none !important;
      }
      .image-responsive svg{
        visibility: visible !important;
        display: block !important;
        margin: 0 auto;
        max-width: 80dvw;
      }
    }
  }
}

@media (max-width: 520px) {
  .home-header {
    padding: 35px 20px 0px;
    margin-bottom: 20px;
    row-gap: 1rem;
  }
  .home-header-container {
    padding: 1rem;
  }
  .home-header-description h1 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 25px;
  }
  .home-header-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .home-header-image svg {
    width: 355px;
    height: 300px;
  }
  .home-header-image Button {
    font-size: 12px !important;
    line-height: 24px !important;
  }
  .test-predicta-bttn {
    display: none !important;
  }
  .predicta-steps-wrapper {
    gap: 0.5rem !important;
    .predicta-step {
      gap: 0.5rem !important;
      .image {
        display: none !important;
      }
    }
  }
  .qualiopi {
    flex-direction: column !important;
    align-items: center;
  }
}
/* COOKIES */
.cookies {
  background-color: #ffffff;
  width: 94%;
  margin: 0px auto;
  border-radius: 8px;
  box-shadow: 0px 0px 20px #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 34px 14px 18px;
  position: fixed;
  bottom: 5%;
  left: 3%;
}
.cookies-infos {
  display: flex;
  align-items: center;
  column-gap: 14px;
}
/* .cookies-infos div {
  max-width: 90%;
} */
.cookis-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #022282;
  margin-bottom: 4px;
}
.cookies-description {
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  color: #161616;
}
.cookies-description span {
  font-weight: 700;
  color: #022282;
}
.cookies-buttons-container {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.cookies-accept,
.cookies-customize,
.cookies-refuse {
  border-radius: 4px !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-size: 10px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  width: 93px !important;
  border: 2px solid #022282 !important;
  box-shadow: none !important;
  padding: 0px 14px !important;
}
.cookies-customize:hover,
.cookies-accept:hover,
.cookies-refuse:hover {
  color: #ffffff !important;
  background-color: #022282 !important;
}
.cookies-customize,
.cookies-accept,
.cookies-refuse {
  background-color: #ffffff !important;
  color: #022282 !important;
}
@media (max-width: 960px) {
  .cookies {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 15px;
    padding: 13px 10px;
  }
}
@media (max-width: 600px) {
  .cookies-infos div {
    max-width: 85%;
  }
}
/* Cookies Modal */
.cookies-modal {
  background-color: #ffffff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px !important;
  padding: 2% !important;
  border: none !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0%;
}
.cookies-modal-infos {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  margin-top: 10px;
}
.cookies-modal-description {
  max-width: 274px;
}
.cookies-modal-description h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  line-height: 15.6px;
  color: #022282;
  margin-bottom: 15px;
}
.cookies-modal-description p {
  font-family: Poppins;
  font-size: 11px;
  line-height: 14px;
  color: #161616;
}
.cookies-modal-description p span {
  color: #022282;
  font-weight: 700;
}
.global-switch-label .MuiFormControlLabel-label {
  font-family: Poppins;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #022282;
}
.switch-label .MuiFormControlLabel-label {
  font-family: Poppins;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #161616;
}
/* Predicta 4 steps Component */
.predicta-steps-container {
  background-color: #ffffff;
  font-family: Poppins;
}
.predicta-steps-content {
  margin: auto;
  padding: 4rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1035px;
  .titles {
    display: flex;
    flex-direction: column;
    p {
      color: #000000;
      /* text-align: center; */
      font-size: 20px;
      font-style: normal;
      line-height: 34px; /* 141.667% */
    }
    h1 {
      margin: 0 0 1.5rem;
      color: #022282;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      span {
        color: #FF511A;
      }
    }
  }
}
.predicta-steps-wrapper {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  .predicta-step {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    border-top: 1px solid rgb(244, 244, 244);
    padding: 3rem 0 0;
    .text {
      display: flex;
      flex-direction: column;
      max-width: 498px;
      h2 {
        font-size: 2rem;
        color: #FF511A;
        font-weight: 600;
      }
      h3 {
        font-size: 1.5rem;
        color: #022282;
        font-weight: 400;
        span {
          /* color: #FF511A; */
          font-weight: 500;
        }
      }
      ul {
        font-weight: 400;
      }
    }
    .image {
      display: flex;
      align-items: center;
    }
    .image-responsive svg {
      visibility: collapse;
      display: none;
    }
  }
}

.law {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 12px;
  background: #E7F0FF;
  padding: 1rem;
  margin: 1rem 0;
  .law-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    p {
      margin: 0;
      color: #022282;
      font-weight: 500;
    }
  }
}

.qualiopi {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 12px;
  background: #E7F0FF;
  padding: 1rem;
  margin: 1rem 0;
  p {
    color: #022282;
    font-weight: 500;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .qualiopi-logo {
    width: 250px;
    border-radius: 25px;
  }
}
/* Predicta Description Component */
.predicta-description-container {
  background-color: #ffffff;
}
.predicta-description-content {
  margin: auto;
  padding: 20px 10px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1035px;
  flex-wrap: wrap;
}
.predicta-description-content h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #022282;
  margin-bottom: 50px;
}
.predicta-description-content h3 span {
  color: #ff511a;
}

.predicta-description-subContent  {
  padding: 20px;
  gap: 10px;
 
  
  background: #E7F0FF;
  border-radius: 12px;
  margin-bottom: 5rem;
}


.predicta-description-subContent p {
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 20px; 
  color: #022282;
  text-align: center;

  line-height: 30px;
}

.description-items-container {
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}
.description-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 60px;
  row-gap: 20px;
}
.item-image {
  width: 467px;
}
.item-image-mobile {
  display: none;
}
.item-infos {
  max-width: 467px;
}
.item-infos h2 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #022282;
  margin-bottom: 20px;
  text-align: initial;
}
.item-infos h2 span {
  color: #ff511a;
}
.item-infos p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #161616;
  margin-bottom: 15px;
}
.indication-info {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 15px;
}
.indication-info span {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #161616;
}
.highlighted-info {
  font-family: Poppins;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  /* text-decoration: underline; */
  color: #0034ba !important;
  display: inline;
  cursor: pointer;
}
.highlighted-info-text {
  font-family: Poppins;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #161616 !important;
  cursor: pointer;
}
.highlighted-info-span {
  text-decoration: none;
  display: inline-block;
}
.highlighted-info:hover {
  color: #1cecba;
}
.home-responsive-item-title {
  display: none;
}
@media (max-width: 1035px) {
  .home-responsive-item-title {
    display: block;
    max-width: 467px;
  }
  .home-responsive-item-title h3 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #022282;
    margin-bottom: 20px;
    text-align: initial;
  }
  .item-infos h2 {
    display: none;
  }
}
@media (max-width: 520px) {
  .predicta-description-content {
    padding: 20px 20px 30px;
  }
  .predicta-description-content h1 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  .description-items-container {
    row-gap: 40px;
  }
  .description-item {
    row-gap: 0px;
  }
  .item-image-mobile {
    width: 335px;
    height: 300px;
    display: block;
  }
  .item-image  {
    display: none;
  }
  .home-responsive-item-title h3 {
    font-size: 18px;
    line-height: 26px; 
  }
  .item-infos h6 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .indication-info span {
    font-size: 12px;
    line-height: 14px;
  }
  .highlighted-info {
    font-size: 14px;
    line-height: 21px;
  }
  .predicta-description-subContent p{
    font-size: 18px;
  }
}
@media (max-width: 375px) {
  .item-image-mobile {
    width: 310px;
    height: 290px;
  }
}
/* Predicta Team */
.predicta-team {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0,
      rgba(255, 255, 255, 0.6) 0
    ),
    url("../assets/images/predicta-team.png") no-repeat center;
  padding-top: 37%;
  background-size: contain;
}
/* Download Guide Home Component */
.download-guide-home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 60px 20px 80px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1035px;
}
.download-guide-home-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #022282;
  margin-bottom: 60px;
}
.download-guide-home-content h1 span {
  color: #ff511a;
}
.download-guide-home-content p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #161616;
  margin-bottom: 20px;
}
.download-guide-home-content Button {
  border-radius: 8px !important;
  background-color: #022282 !important;
  color: #fff !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  padding: 11px 10px;
  box-shadow: none !important;
  position: unset;
}

.download-guide-home-content Button:hover {
  background-color: #022282 !important;
  color: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}
