.nav-bar-container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}
.top-nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  background-color: #E7F0FF;
}
.top-nav-bar .top-nav-bar-text  {
  font-family: Poppins;
  font-size: 14px;
  line-height: 18px;
  color: #161616;
  font-weight: 400;
}
.top-nav-bar .mobile-top-nav-bar-text  {
display: none;
}
.download-guide-bttn {
  border: 2px solid #022282 !important;
  border-radius: 8px !important;
  padding: 0px 10px !important;
  color: #022282 !important ;
  font-family: Montserrat !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: center !important;
  margin: 10px 0px !important;
  text-transform: unset !important;
}

.download-guide-bttn-top {
  color: #0034BA !important ;
  font-family: 'Poppins' !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: center !important;
  margin: 10px 0px !important;
  text-transform: unset !important;
}

.download-guide-bttn-top:hover {
  color: #1CECBA !important;
}

.bottom-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 4% 20px 5%;
  background-color: #ffffff;
}
.bottom-nav-bar-right,
.bottom-nav-bar-left {
  display: flex;
  align-items: center;
}
.bottom-nav-bar-left {
  gap: 50px;
}
.nav-bar-links {
  margin-top: 10px;
}
.select-nav-bar div {
  padding: 3px 32px !important;
}

.link {
  margin: 0 12px;
  display: inline-flex;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  cursor: pointer;
}
.link:hover {
  color: #022282;
  border-bottom: none;
}
.active-link {
  color: #161616;
  border-bottom: 3px solid #ff511a;
}
.product-item,
.ressource-item {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: #022282;
}
.connect-button {
  border: 2px solid #ff511a !important;
  border-radius: 8px !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ff511a !important;
  text-transform: none !important;
  height: 48px;
  padding: 11px 20px !important;
}
.download-guide-bttn:hover {
  background-color: #022282 !important;
  color: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}
.responsive-bottom-nav-bar,
.responsive-nav-bar-links {
  display: none;
}
@media (max-width: 1100px) {
  .bottom-nav-bar {
    padding: 20px 2%;
  }
  .bottom-nav-bar-left {
    gap: 30px;
  }
  .link {
    margin: 0 10px;
  }
}
@media (max-width: 1020px) {
  .bottom-nav-bar {
    padding: 20px 1%;
  }
  .connect-button {
    font-size: 16px !important;
  }
}
@media (max-width: 990px) {
  .bottom-nav-bar-left {
    gap: 1rem;
  }
  .link {
    margin: 0 8px;
  }
}
@media (max-width: 960px) {
  .top-nav-bar .top-nav-bar-text  {
    max-width: 58%;
  }
  .download-guide-bttn {
    padding: 6px 6px !important;
  }
  .bottom-nav-bar {
    display: none;
  }
  .responsive-bottom-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 3%;
    background-color: #ffffff;
  }
  .responsive-nav-bar-right {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }
  .responsive-connect-button {
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #ff511a !important;
    text-transform: none !important;
  }
  .nav-bar-menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .responsive-nav-bar-icon {
    cursor: pointer;
    height: 50px;
    width: 170px;
  }
  .responsive-test-predicta-bttn {
    display: none !important;
  }
  .responsive-nav-bar-links {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: absolute;
    background: #fefeff;
    top: 100%;
    left: 0px;
    height: 100vh;
    width: 100%;
    padding: 40px 30px 0px 30px;
  }

  .responsive-nav-bar-links li {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }
  .bleu {
    color: #022282 !important;
  }
}
@media (max-width: 620px) {
  .top-nav-bar .top-nav-bar-text  {
    font-size: 9px;
    line-height: 12px;
  }
  .download-guide-bttn , .download-guide-bttn-top  {
    font-size: 8px !important;
    line-height: 20px !important;
  }
}
@media (max-width: 520px) {
  .top-nav-bar {
    column-gap: 10px;
  }
  
  .responsive-bottom-nav-bar {
    padding: 10px 3%;
  }
  .top-nav-bar {
    padding: 0 3%;
  }
  .responsive-nav-bar-right {
    column-gap: 25px;
  }
  .responsive-nav-bar-links li {
    gap: 0px;
    justify-content: space-between;
  }
  .nav-bar-menu-icon {
    width: 24px;
    height: 24px;
  }
  .responsive-nav-bar-icon {
    height: 35px;
    width: 130px;
  }
  .responsive-test-predicta-bttn {
    display: block !important;
    background-color: #ff511a !important;
    border-radius: 8px !important;
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #ffffff !important;
    padding: 5px 10px !important;
    text-transform: none !important;
  }
  .download-guide-bttn {
    padding: 2px 3px !important;
  }
  .top-nav-bar .top-nav-bar-text {
    display: none;
   }
   .top-nav-bar .mobile-top-nav-bar-text {
     display: block;
     font-size: 10px;
     line-height: 130%; 
     text-align: center;
     
     color: #161616;
   }
}
@media (max-width: 375px) {
  .top-nav-bar {
    column-gap: 10px;
  }

  .download-guide-bttn {
    line-height: 18px !important;
  }
}

@media (max-width: 360px) {
  .responsive-nav-bar-right {
    column-gap: 15px;
  }
}
