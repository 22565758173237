.footer-container {
  background: #ebeef3;
}
.footer-content {
  /* box-shadow: 0px 0px 30px rgba(219, 228, 240, 0.51); */
  max-width: 1035px;
  padding: 45px 20px;
  margin: auto;
}
.footer-top {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}
.predicta-links {
  display: flex;
  column-gap: 60px;
  flex-wrap: wrap;
  row-gap: 20px;
}
.linked-in-link {
  display: flex;
  column-gap: 5px;
}
.footer-icon {
  display: flex;
  align-items: center;
  column-gap: 40px;
  flex-wrap: wrap;
  row-gap: 20px;
}
.divider {
  border-top: 1px solid rgba(2, 34, 130, 0.7) !important;
  margin-top: 20px;
}

.predicta h6,
.ressources h6,
.contact h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #062484;
  margin-bottom: 5px;
}
.predicta p,
.ressources p,
.contact p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #062484;
  margin-bottom: 5px;
  cursor: pointer;
}
.linked-in-description,
.footer-icon-description {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #062484;
}
.footer-bottom {
  display: flex;
  align-items: center;
  column-gap: 38px;
  margin-top: 12px;
}
.footer-bottom-links {
  display: flex;
  align-items: center;
  column-gap: 38px;
}
.footer-bottom h6 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 28.5px;
  color: #062484;
}
.footer-bottom p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #062484;
}
@media (max-width: 769px) {
  .footer-top {
    flex-direction: column-reverse;
    row-gap: 20px;
  }
}
@media (max-width: 710px) {
  .footer-bottom {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .footer-bottom-links {
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;
  }
}
@media (max-width: 520px) {
  .footer-content {
    padding: 20px;
  }
  .footer-links,
  .predicta-links {
    flex-direction: column;
  }
}
