.responsive-calendly-iframe {
  position: absolute;
  height: 646px;
  width: 1000px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

@media (max-width: 1298px) {
  .responsive-calendly-iframe {
    position: absolute;
    height: 70%;
    width: 50%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
}

@media (max-width: 865px) {
  .responsive-calendly-iframe {
    position: absolute;
    height: 70%;
    width: 75%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
}
