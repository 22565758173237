/* Download Guide Form */
.download-guide-form-content {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 85px 20px;
  display: flex;
  column-gap: 30px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.download-guide-form-description {
  /* max-width: 498px; */
  display: flex;
  flex-direction: column;
}
.form-white-book {
  width: 467px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px rgba(11, 43, 92, 0.08);
  display: flex;
  flex-direction: column;
  padding: 25px 40px 35px;
}
.form-white-book h5 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #022282;
  margin: 10px 0px 8px;
}
.form-white-book h5 span {
  color: #ff511a;
}
.form-white-book Button {
  width: 100%;
  background-color: #022282 !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  padding: 10px 0px !important;
  text-transform: none !important;
}




.download-guide-form-description h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 38px;
  line-height: 49px;
  color: #022282;
  margin-bottom: 15px;
}
.download-guide-form-description h1 span {
  color: #ff511a;
}
.download-guide-form-description p {
  font-family: Poppins;
  font-weight: 400;
  /* font-size: 16px;
  line-height: 24px; */
  color: #161616;
}
.guide-description-icon {
  margin-left: 35px;
  width: 389px;
  height: 326px;
}

.guide-form-description {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #161616;
  margin: 15px 0px 20px;
}
.guide-form-description span {
  text-decoration: underline;
  color: #216dff;
}


.form-sended-card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px rgba(11, 43, 92, 0.08);
  display: flex;
  flex-direction: column;
  padding: 128px 55px;
}
.form-sended-card h4 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #022282;
  text-align: center;
  margin-bottom: 10px;
}
.form-sended-card p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #161616;
  text-align: center;
}
@media (max-width: 520px) {
  .guide-description-icon {
    width: 335px;
    height: 300px;
    margin-left: 0px;
  }
}
@media (max-width: 375px) {
  .guide-description-icon {
    width: 310px;
    height: 290px;
  }
}
/* Guide Description */
.guide-description-container {
  background-color: #fff;
}
.guide-description-content {
  margin: auto;
  max-width: 1035px;
  padding: 60px 20px;
  display: flex;
  column-gap: 60px;
  flex-wrap: wrap;
  justify-content: center;
}
.guide-description {
  max-width: 456px;
}
.guide-description h3 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #022282;
  margin-bottom: 30px;
}
.description-list {
  list-style: none;
}
.description-list li::before {
  content: "\2022";
  color: #022282;
  font-weight: 700;
  display: inline-block;
  width: 5px;
  margin-right: 10px;
}
.description-list li {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #161616;
  margin-bottom: 20px;
}
.description-list li span {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #022282;
}
.guide-description-image {
  width: 467px;
}

@media (max-width: 375px) {
  .guide-description-image {
    width: 310px;
    height: 290px;
  }
}
/* Guide Advices */
.guide-advices-container {
  background-color: rgba(219, 228, 240, 0.4);
}
.guide-advices-content {
  margin: auto;
  max-width: 1035px;
  padding: 58px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.guide-advices-content h1 {
  max-width: 848px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #022282;
  text-align: center;
  margin-bottom: 40px;
}
.guide-advices-content h1 span {
  color: #ff511a;
}
.advices-cards {
  display: flex;
  justify-content: center;
  column-gap: 48px;
  flex-wrap: wrap;
  row-gap: 35px;
}
.advice-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 239px;
  align-self: normal;
}
.advice-item h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000;
  text-align: center;
}
.advice-item p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  text-align: center;
}
.advice-one {
  margin-top: 20px;
}
.advice-three {
  margin-top: 39px;
}
.guide-question {
  margin: auto;
  max-width: 898px;
  padding: 46px 20px 0px;
  margin-bottom: -40px;
}
.guide-question h2 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  color: #022282;
  text-align: center;
}
.error-msg {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff511a;
  margin-top: 10px;
}
@media (max-width: 520px) {
  .guide-description-image {
    width: 335px;
    height: 300px;
  }
  .guide-question h2{
    font-size: 20px;
    line-height: 26px;
  }
}