.contact-formation {
  margin: auto;
  padding: 60px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e8ebf6;
}
.contact-formation h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  color: #022282;
  text-align: center;
  max-width: 870px;
  margin-bottom: 40px;
}

.formation-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 60px 20px 0px;
}
.formation-main-content {
  display: flex;
  flex-direction: column;
  max-width: 1035px;
}
.formation-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 2rem;
  line-height: 41px;
  color: #022282;
  margin-bottom: 40px;
  text-align: center;
  span {
    color: #ff511a;
  }
}
.formation-first-image {
  width: 45%;
}
.formation-sur-mesure {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 1rem 0 4rem;
  justify-content: space-between;
  .texts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 488px;
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      color: #022282;
    }
    h3 {
      color: #161616;
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .formation-image {
    height: 280px;
  }
}
.formations-examples {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 5rem;
  padding: 0 0 4rem;
  max-width: 1035px;
  /* align-items: center; */
  h2 {
    font-size: 28px;
    font-weight: 600;
    color: #022282;
    font-family: Poppins;
    line-height: 130%;
    text-align: center;
    span {
      color: #ff511a;
    }
  }
  .formation-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    cursor: pointer;
    .formation-text {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #022282;
      }
      p {
        font-size: 18px;
      }
    }
    .formation-three-image-responsive {
      display: none;
    }
  }
}



.formation-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 60px;
  color: #161616;
  max-width: 680px;
}

.formation-image-description {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  max-width: 465px;
  text-align: center;
}
.contact-formation button {
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #022282 !important;
  background-color: #e8ebf6 !important;
  border: 3px solid #022282;
  box-shadow: 0px 0px 50px rgba(11, 43, 92, 0.18);
  border-radius: 12px;
  text-transform: none !important;
  padding: 8px 70px !important;
}

.bodyContact .preventive-cards-wrapper,.LiteratureBody .preventive-cards-wrapper,
.contact-formation {
  background: #eff6ff;
}
.formation-body {
  background-color: #FFFFFF;
}

.formation-cards-container {
  .cards-wrapper {
    .card {
      height: 300px;
      .card-title {
        margin: 0.5rem 0;
      }
    }
  }
}

@media (max-width: 520px) {
  
  .formation-main-container {
    padding: 2rem 1rem;
  }
  /* .formation-title {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 27px;
  } */
  .formation-desc {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .formation-items {
    row-gap: 20px;
  }
  .formation-item-card {
    margin-top: 0px;
  }
  .formation-card-title {
    font-size: 16px;
    line-height: 24px;
  }
  .formation-item-card > img {
    width: 280px;
    height: 155px;
  }
  .formation-image-description {
    font-size: 14px;
    line-height: 24px;
  }
  .contact-formation {
    padding: 30px 10px 0px 10px;
  }
  .contact-formation h1 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 25px;
  }
}
.formation-two-image {
  cursor: pointer;
}
/* Formation Page Header Component */

.formation-header-container h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #022282;
  margin-bottom: 40px;
}
.formation-header-content {
  margin: auto;
  padding: 40px 20px 100px;
  max-width: 1035px;
}
.foramation-header-description {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 130px;
  row-gap: 30px;
}
.formation-header-left {
  max-width: 528px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formation-header-left h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #161616;
  margin-top: 10px;
}
.formation-header-right {
  max-width: 337px;
  display: flex;
  flex-direction: column;
}
.formation-header-right h5 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  margin-bottom: 27px;
}
.formation-header-right h4 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  margin: 23px 0px 15px;
}
.formation-header-right ul {
  max-width: 326px;
  list-style: none;
  list-style-position: outside;
}
.formation-header-right ul li {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000;
  margin-bottom: 12px;
}
.formation-header-right ul li::before {
  content: "\2022";
  color: #022282;
  font-weight: 700;
  display: inline-block;
  text-indent: -1em;
}
.get-program {
  background-color: #ff511a !important;
  border-radius: 8px !important;
  border: 1px solid #ff511a !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 10px 10px !important;
  text-transform: none !important;
  margin-bottom: 15px !important;
  width: fit-content;
  height: 48px;
}
.get-quote {
  background-color: transparent !important;
  border-radius: 8px !important;
  border: 3px solid #ff511a !important;
  color: #ff511a !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 10px 11px !important;
  text-transform: none !important;
  width: fit-content;
  height: 48px;
}
@media (max-width: 550px) {
  .formation-header-left {
    max-width: 500px;
  }
  .formation-header-left > img {
    width: 500px;
  }
}
@media (max-width: 520px) {
  .formation-header-container h1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .formation-header-left > img {
    width: 400px;
  }
  .formation-header-left h5 {
    margin-top: 10px;
  }
}
@media (max-width: 410px) {
  .formation-header-left > img {
    width: 350px;
  }
}
/* Formation Page Main */
.formation-page-main {
  background-color: #fff;
}
.formation-page-main-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 60px 20px 80px;
  justify-content: center;
  max-width: 1035px;
  row-gap: 10px;
}
.formation-page-main-content .header {
  display: flex;
  align-items: center;
  background-color: rgba(2, 34, 130, 0.1);
  padding: 18px 35px;
  border-radius: 8px;
}
.formation-page-main-content .header h5 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #022282;
  margin-left: 20px;
  max-width: 85%;
}
.presentation-formation {
  display: flex;
  justify-content: center;
  column-gap: 80px;
}
.presentation-formation-infos {
  max-width: 578px;
}
.formation-infos-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #022282;
  margin: 50px 0px 30px;
}
.formation-infos-subtitle {
  font-family: Poppins;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000;
  margin-bottom: 30px;
}
.formation-goals li,
.presentation-formation-infos p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.formation-goals {
  padding-left: 23px;
}
.presentation-formation-infos p {
  margin-bottom: 20px;
}
.presentation-formation-image {
  position: sticky;
  width: 100%;
  height: 100%;
  top: 23vh;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.presentation-formation-image h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #161616;
  margin: 10px 0px 15px;
}
@media (max-width: 1035px) {
  .formation-sur-mesure {
    flex-direction: column !important;
    align-items: center;
    .texts {
      width: fit-content;
      max-width: none;
    }
  }
  .formations-examples {
    gap: 4rem;
    padding: 0 2rem 2rem;
    /* align-items: center; */
  }
  .formation-main-content {
    max-width: none;
    width: 100%;
  }
  .presentation-formation {
    flex-wrap: wrap;
  }
  .presentation-formation-image {
    position: relative;
    width: auto;
    height: auto;
    top: 0px;
    margin-top: 20px;
  }
}
@media (max-width: 780px) {
  .formations-examples {
    .formation-wrapper {
      flex-direction: column;
    }
  }
  .formation-three-image-desktop {
    display: none;
  }
  .formation-three-image-responsive {
    display: flex !important;
  }
}
@media (max-width: 520px) {
  .formation-page-main-content .header {
    justify-content: space-between;
    padding: 18px 20px;
  }
  .formation-page-main-content .header h5 {
    margin-left: 0px;
  }
  .formation-sur-mesure {
    .formation-image {
      height: 200px;
    }
  }
}
/* Formation Statistics component */
.formations-statistics-container {
  background-color: #022282;
  background-image: url("../assets/images/formation-background-icon.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -45px;
}
.formations-statistics-content {
  margin: auto;
  padding: 38px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 995px;
}
.formations-statistics-content > h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 31.5px;
  color: #fff;
  margin-bottom: 40px;
}
.formation-statistics-cards {
  display: flex;
  justify-content: center;
  column-gap: 35px;
  flex-wrap: wrap;
  row-gap: 27px;
}
.formation-statistics-card {
  width: 480px;
  background-color: #ffffff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  padding: 14px 12px;
}
.formation-statistics-card > h2 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 60px;
  line-height: 82px;
  color: #ff511a;
  display: flex;
}
.formation-statistics-card > h2 > span {
  font-size: 25px;
  line-height: 34px;
  margin-top: 10px;
}
.formation-statistics-card > h4 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #161616;
}
.formation-statistics-card > h4 > span {
  color: #ff511a;
}
.formations-statistics-source {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}
.formations-statistics-source > p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  padding-left: 20px;
}
@media (max-width: 520px) {
  .formations-statistics-content > h1 {
    font-size: 18px;
    line-height: 32px;
  }
  .formation-statistics-card {
    width: 400px;
  }
  .formation-statistics-card > h2 {
    font-size: 40px;
    line-height: 55px;
  }
  .formation-statistics-card > h2 > span {
    font-size: 24px;
    line-height: 33px;
    margin-top: 0px;
  }
  .formation-statistics-card > h4 {
    font-size: 16px;
    line-height: 22px;
  }
  .formations-statistics-source > p {
    font-size: 8px;
    line-height: 22px;
  }
}
@media (max-width: 410px) {
  .formation-statistics-card {
    width: 370px;
    padding: 14px 10px;
  }
}
@media (max-width: 380px) {
  .formation-statistics-card {
    width: 350px;
    padding: 14px 10px;
  }
}
/* Other Formations */
.other-formations-content {
  margin: auto;
  padding: 60px 20px 0px;
  max-width: 1035px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.other-formations-content h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  margin-bottom: 60px;
}
.other-formation-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
  row-gap: 40px;
  flex-wrap: wrap;
}
.other-formation-card {
  background-color: #fff;
  padding: 16px 15px 35px;
  border-radius: 12px;
  align-self: normal;
  width: 400px;
}
.other-formation-image {
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
}
.other-formation-card h3 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #161616;
  text-align: center;
  max-width: 324px;
  margin: 25px auto 0px;
}
@media (max-width: 410px) {
  .other-formation-card {
    width: 360px;
    padding: 16px 10px 30px;
  }
  .other-formation-image {
    width: 340px;
  }
}
/* Program Modal  Quote Modal*/
.program-modal-container {
  background-color: #fff !important;
  position: relative;
  max-width: 50%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px !important;
  border: none !important;
  padding: 53px 70px;
}
.form-formation {
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.quote-modal-container {
  background-color: #fff !important;
  position: relative;
  max-width: 70%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px !important;
  border: none !important;
  padding: 43px 70px;
}
.program-modal-container h1,
.quote-modal-container h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #062484;
  text-align: center;
  margin-bottom: 4px;
}
.program-modal-container h3,
.quote-modal-container h3 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #161616;
  text-align: center;
  margin: 0px auto 20px;
  max-width: 550px;
}
.program-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quote-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quote-inputs-container h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #161616;
  margin: 5px 0px;
}
.quote-inputs-container h4 span {
  color: #ff511a;
}
.quote-inputs-container input[type=text] {
  height: 2em;
}
.quote-inputs-container input[type=number] {
  height: 2em;
}
.program-inputs-container input[type=text] {
  height: 2.5em;
}
.program-inputs-container input[type=number] {
  height: 2.5em;
}
.program-inputs-container h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #161616;
  margin: 8px 0px;
}
.program-inputs-container h4 span {
  color: #ff511a;
}

.guide-form-input div {
  height: 40px !important;
  border-radius: 8px !important;
}
.guide-form-input > div > input {
  padding: 8.5px 14px;
}
.message-span {
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #161616 !important;
}
.program-modal-description {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #161616;
  margin: 10px 0px;
}
.program-modal-description span {
  text-decoration: underline;
  color: #216dff;
}
.program-modal-bttn {
  background-color: #ff511a !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 10px 0px !important;
  text-transform: none !important;
  width: 100%;
}
.form-sended-modal {
  padding: 70px 46px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px !important;
  border: none !important;
  max-width: 389px;
}
.form-sended-modal h4 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #022282;
  margin-bottom: 15px;
  text-align: center;
}
.form-sended-modal p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #161616;
  text-align: center;
  margin-bottom: 15px;
}
.form-sended-modal Button {
  background-color: #ff511a !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 9px 0px !important;
  text-transform: none !important;
}

@media (max-width:900px) {
  .program-modal-container {
    max-width: 80%;
    padding: 1em 2em;
  }
  .program-modal-container h1 {
    font-size: 1em;
  }
  .program-modal-container h3 {
    margin: 0 auto;
  }
  .program-inputs-container h4 {
    font-size: 11px;
  }
  .program-inputs-container input[type=text] {
    height: 2em;
  }
  .program-inputs-container input[type=number] {
    height: 2em;
  }
  .program-modal-bttn {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .quote-modal-container {
    max-width: 80%;
    padding: 1em 2em;
  }
  .quote-modal-container h1 {
    font-size: 1em;
  }
  .quote-modal-container h3 {
    margin: 0 auto;
    font-size: 12px;

  }
  .quote-inputs-container h4 {
    font-size: 12px;
  }
  .mon-message input[type=text] {
    min-height: 2em;
  }
}