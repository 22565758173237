.bodyContact {
    /* background: #E8EBF6; */
}
.title{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 137%;
text-align: center;
color: #022282;
padding-top: 5rem;
}

.title span{
    color:#FF511A;
}

.subTitle{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 30px;
color: #161616;
text-align: center;
margin-top: 3rem;
margin-bottom: 3rem;
}

.CardForm {
    background: #FFFFFF;
box-shadow: 2px 2px 52px 21px rgba(219, 228, 240, 0.8) !important;
border-radius: 20px !important;
padding: 3rem 1.5rem;
}

.descIcon {
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;

color: #161616;
margin-bottom: 1rem;
}

.titleForm{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 55px;
text-align: center;
/* identical to box height, or 139% */
color: #062484;
}

.subTitleForm{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
/* identical to box height */
text-align: center;

color: #FF511A;
}
.text-field label {
    /* z-index: 0 !important; */
}
.agree-container{
    align-items: flex-start !important;
    flex-wrap: nowrap !important;
    padding: 1em 0.5em 0 0.5em;
}
.agree{
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
color: #464741
}
.agree-checkbox{
padding-top: 0px !important;
}

.SubmitContact {
    width: 100%;
    background: #FF511A !important;
box-shadow: 0px 0px 20px #DFDDE2;
border-radius: 8px !important;
height: 48px;
margin-top: 2em !important;
padding: 11px 20px !important;
font-size: 16px !important;
text-transform: none !important;
}

.bodyContact .fix-an-appointment-container {
    /* background: #E8EBF6; */
  }

.contact-form-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 1em;
    padding: 3em 2em;
}

.left-description {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}

.contact-form-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 67%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em 0 0 0;
    margin: 0 0.5em 0 2em;
}

.inputs1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
}

/* .text-field {
    box-sizing: border-box;
    width: 100%;
    min-height: 1.4375em;
    padding: 16.5px 14px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid #C4C4C4;
} */

.mon-message input[type=text] {
    min-height: 5em;
}

.input-row {
    box-sizing: border-box;
    width: 100%;
}

input[type=text] {
    box-sizing: border-box;
    width: 100%;
    min-height: 1.4375em;
    padding: 16.5px 14px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid #C4C4C4;
}

input[type=number] {
    box-sizing: border-box;
    width: 100%;
    min-height: 1.4375em;
    padding: 16.5px 14px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid #C4C4C4;
}
.error-msg-contact {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ff511a;
    margin-top: 10px;
  }

@media screen and (max-width: 900px) {
    .left-description {
        display: none;
    }
    .contact-form-wrapper {
        flex-basis: 100%;
    }
}
@media screen and (max-width: 600px) {
    .titleForm{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 55px;
        text-align: center;
        color: #062484;
    }

    .subTitleForm{
        font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
text-align: justify;
color: #FF511A;
    }
    .agree{
        font-size: 10px;
        line-height: 15px;
    }
    .CardForm {
        background: #FFFFFF;
    box-shadow: 2px 2px 52px 21px rgba(219, 228, 240, 0.8) !important;
    border-radius: 20px !important;
    padding: 1rem;
    }

    .title{
        text-align: left;
        font-size: 20px;
line-height: 137%;
    }
    .subTitle{
        display: none;
    }
  }

/* Contact Modal */
.contact-form-sended-modal {
  padding: 47px 35px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px !important;
  border: none !important;
  max-width: 389px;
}
.contact-form-sended-modal h4 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #022282;
  margin:10px 0px 15px;
  text-align: center;
}
.contact-form-sended-modal p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #161616;
  text-align: center;
  margin-bottom: 20px;
}
.contact-form-sended-modal Button {
  background-color: #ff511a !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 12px 0px !important;
  text-transform: none !important;
  width: 96px !important;
}