/* About Us Header */
.about-us-header-wrapper {
  /* background-color: #e7f0ff; */
}
.about-us-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  padding: 50px 20px 80px;
  max-width: 1035px;
}
.item-with-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  margin-bottom: 40px;
}
.responsive-item-title {
  display: none;
}
.item-with-image-description {
  max-width: 588px;
}
.item-with-image-description h1,
.our-mission h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  color: #022282;
  margin-bottom: 20px;
}
.item-with-image-description p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #161616;
}
.item-with-image-description h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 20px;
}
.item-with-image-description ul li {
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 20px;
}
.our-mission p span {
  font-family: Poppins;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  padding: 5px 8px;
  background-color: #ff511a;
  display: inline-block;
}
.third-item-icon {
  width: 377px;
}
@media (max-width: 1034px) {
  .responsive-item-title {
    display: block;
    width: 588px;
  }
  .responsive-item-title h1 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 35px;
    line-height: 41px;
    color: #022282;
    margin-bottom: 20px;
  }
  .first-item-title {
    display: none;
  }
  .second-item-icon,
  .third-item-icon {
    display: none;
  }
  .item-with-image {
    width: 100%;
  }
}
@media (max-width: 620px) {
  .responsive-item-title {
    width: 100%;
    text-align: start;
  }
}
@media (max-width: 577px) {
  .our-mission p span {
    display: inline;
  }
}
@media (max-width: 520px) {
  .about-us-header-content {
    padding: 40px 20px;
  }
  .responsive-item-title h1,
  .item-with-image-description h1,
  .our-mission h1 {
    font-size: 28px;
    line-height: 32px;
  }
  .first-item-icon {
    width: 150px;
    height: 150px;
  }
  .second-item-icon {
    display: none;
  }
  .item-with-image-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .item-with-image-description h6 {
    display: none;
  }
  .item-with-image-description ul {
    list-style: none;
  }
  .item-with-image-description ul li {
    font-size: 16px;
    line-height: 22px;
  }
  .item-with-image-description ul li b {
    color: #022282;
  }
  .our-mission p span {
    font-size: 20px;
    line-height: 30px;
    padding: 4px 4px;
  }
}
/* Our Values */
.our-values-wrapper {
  background-color: #ffffff;
}
.our-values-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 40px 0px 50px;
  max-width: 1025px;
}
.our-values-title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  margin-bottom: 70px;
}
.our-values-cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 34px;
}
.value-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0px 0px 20px rgba(11, 43, 92, 0.08);
  padding: 45px 26px 30px;
  width: 310px;
  align-self: normal;
}
.value-card-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #022282;
  margin: 30px 0px 5px;
  text-align: center;
}
.value-card-descritpion {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  text-align: center;
}
@media (max-width: 520px) {
  .our-values-conatiner {
    padding: 20px 0px;
  }
  .our-values-title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }
  .our-values-cards-wrapper {
    column-gap: 10px;
    row-gap: 14px;
  }
  .value-card {
    padding: 16px 5px;
    width: 110px;
  }
  .value-card-title {
    font-size: 9px;
    line-height: 12px;
    margin-top: 10px;
  }
  .value-card-descritpion {
    display: none;
  }
  .value-icon-one {
    width: 30px;
    height: 42px;
  }
  .value-icon-two {
    width: 38px;
    height: 42px;
  }
  .value-icon-three {
    width: 29px;
    height: 39px;
  }
  .value-icon-four {
    width: 42px;
    height: 42px;
  }
  .value-icon-five {
    width: 35px;
    height: 42px;
  }
}

/* Our Team */
.our-team-wrapper {
  background-color: #ffffff;
}
.our-team-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 50px 0px;
  max-width: 1025px;
}
.our-team-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  margin-bottom: 70px;
}
.our-team-cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 42px;
}
.person-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 309px;
  align-self: flex-start;
}
.pesron-card-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.name {
  font-family: Poppins;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #022282;
  margin-top: 45px;
  text-transform: uppercase;
  text-align: center;
}
.predicta-job {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #ff511a;
}
.job {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  text-align: center;
}
@media (max-width: 520px) {
  .our-team-conatiner {
    padding: 20px 0px 30px;
  }
  .our-team-title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }
  .our-team-cards-wrapper {
    row-gap: 9px;
    justify-content: flex-start;
    padding: 0px 20px;
  }
  .person-card {
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    column-gap: 22px;
  }
  .person-image {
    width: 107px;
    height: 110px;
  }
  .pesron-card-infos {
    align-items: baseline;
  }
  .name {
    font-size: 18px;
    line-height: 22px;
    margin-top: 0px;
  }
  .predicta-job {
    font-size: 16px;
    line-height: 20px;
  }
  .job {
    font-size: 12px;
    line-height: 18px;
    text-align: start;
  }
}
/* Our Mentors */
.our-mentors-wrapper {
  /* background-color: #eff6ff; */
}
.our-mentors-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 30px 0px 50px;
  max-width: 1025px;
}
.our-mentors-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  margin-bottom: 70px;
}
.our-mentors-cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 42px;
}
.mentor-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 309px;
  align-self: flex-start;
}
.mentor-name {
  font-family: Poppins;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #022282;
  margin-top: 45px;
  text-transform: uppercase;
  text-align: center;
}
.mentor-predicta-job {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #ff511a;
  text-align: center;
}
@media (max-width: 520px) {
  .our-mentors-content {
    padding: 20px 0px 30px;
  }
  .our-mentors-title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }
  .our-mentors-cards-wrapper {
    column-gap: 20px;
  }
  .mentor-card {
    width: 107px;
  }
  .mentor-image {
    width: 107px;
    height: 110px;
  }
  .mentor-name {
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
  }
  .mentor-predicta-job {
    font-size: 10px;
    line-height: 12px;
  }
}
